/* navbar.css */
:root {
    --navbar-bg-color: transparent;
    --transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-duration: 0.3s;
  }
  
  /* Navbar Container */
  .navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    backdrop-filter: blur(0px);
    background: var(--navbar-bg-color);
    transition: background var(--transition-duration) var(--transition-easing),
                height var(--transition-duration) var(--transition-easing),
                backdrop-filter var(--transition-duration) var(--transition-easing);
  }
  
  .navbar-container.scrolled {
    background: rgba(0, 0, 0, 0.9) !important;
  }
  
  /* Top Line: Logo/Back and Icons */
  .navbar-top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: 60px;
    gap: 1rem;
    transition: padding var(--transition-duration) var(--transition-easing),
                height var(--transition-duration) var(--transition-easing);
  }

  .navbar-top-line-navbar-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: 60px;
    gap: 1rem;
    margin: auto;
    transition: padding var(--transition-duration) var(--transition-easing),
                height var(--transition-duration) var(--transition-easing);
  }
  
  /* Bottom Line: Categories (Mobile Only) */
  .navbar-bottom-line {
    display: flex;
    gap: 1.5rem;
    padding: 0 2rem 1rem;
    height: 40px;
    /* background: inherit; */
    transition: transform var(--transition-duration) var(--transition-easing),
                opacity var(--transition-duration) var(--transition-easing),
                padding var(--transition-duration) var(--transition-easing);
  }
  
  .navbar-bottom-line.hidden {
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
    padding: 0;
    height: 0;
  }
  
  /* Icon Buttons */
  .nav-icon {
    background: transparent;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
  .nav-icon:hover {
    opacity: 1;
  }
  
  /* Layout for left/right sections */
  .left-section, .right-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .logo {
    width: 35px;
    height: auto;
    border: 0.5px solid white;
    border-radius: 50%;
    transition: opacity 0.3s ease;
  }
  
  /* Category Buttons (Make them slightly smaller on mobile) */
  .navbar-button-btn {
    background: transparent;
    padding: 0rem 0.7rem;
    height: 1.7rem;
    border: 1px solid white;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .navbar-button-btn:hover {
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }
  .navbar-button-btn.active {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    font-weight: bold;
  }

  .close-btn {
    background: transparent;
    border: 1px solid white;
    border-radius: 100%;
    width: 1.6rem;
    height: 1.6rem;
    color: white;
    padding: auto;
  }
  
  /* Back Button */
  .back-button {
    background: transparent;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 0.5rem;
    padding: 0;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 1.5rem;
  }
  .back-button i {
    font-size: 1.3rem;
  }

  /* Modal Overlay */
.category-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  /* width: 100%; */
  height: 100vh;
}

/* Modal Container */
.category-modal {
  /* background-color: #222; */
  background-color: rgba(0, 0, 0, 0.8); 
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0,0,0,0.5);
}

/* Modal Header */
.category-modal h3 {
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 1px solid white;
}

.category-body, .category-modal ul {
  height: 70vh;
  overflow: auto;
}

/* Category List Styling */
.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  margin-bottom: 10px;
}

/* Category Button Options */
.category-option {
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8); 
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease;
}

.category-option:hover,
.category-option:focus {
  background-color: #666;
}

/* Close Button Styling */
.modal-close-btn {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  transition: color 0.2s ease;
}

.modal-close-btn:hover {
  color: #fff;
}

/* Navbar Button: ensure selected category text fits within the button */
.navbar-button-btn .selected-category {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 0.9em;
}

.category-footer {
  display: flex;
  justify-content: flex-end;  /* Aligns buttons to the right; change to 'space-between' if needed */
  gap: 10px;
  margin-top: 15px;
}

.category-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

/* Clear button style */
.clear-btn {
  background-color: transparent;
  color: #fff;
}

/* Close button style with white background */
.category-close-btn {
  background-color: #fff;
  color: #222;
}
  
  /* For Desktop: Hide Bottom Line */
  @media (min-width: 768px) {
    .navbar-bottom-line {
      display: none;
    }
  }
  