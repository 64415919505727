.container-main-page {
    background-color: #000000;
    overflow: hidden;
}

.container-main-page {
    position: relative;
  }
  
  /* Wraps the Cover; its relative positioning allows the fade overlay to be positioned absolutely */
  .cover-wrapper {
    position: relative;
  }
  
  /* Fade overlay: 
     - Positioned absolutely at the bottom of the cover
     - Extends 100px downwards into the tiles area using a negative bottom offset
     - Uses a gradient from the dynamic cover color to black */
  .cover-fade {
    position: absolute;
    bottom: -200px; /* Negative offset makes it overlap the next section */
    left: 0;
    right: 0;
    height: 200px; /* Adjust this height for a more or less gradual fade */
    pointer-events: none;
    transition: background 0.5s ease;
    z-index: 2;
  }
  
  /* Tiles area:
     - Background is set to black to match the fade’s end color
     - A negative top margin pulls it up behind the fade overlay
     - Padding at the top ensures the content isn’t hidden */
  .tiles-wrapper {
    background: black;
    margin-top: -100px; /* Pulls the section upward to meet the fade */
    position: relative;
    padding-top: 100px; /* Creates space for content so it isn’t overlapped by the fade */
  }
  