.seriesContainer {
  background: #000;
  color: #fff;
  min-height: 100vh;
}

/* Backdrop Section */
.backdropSection {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.backdropImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.headerContent {
  position: relative;
  z-index: 2;
  padding: 2rem;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background: linear-gradient(to bottom, transparent 60%, #000);
}

.posterColumn {
  max-width: 250px;
}

.poster {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
}

.title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.metaInfo {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.actionButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Main Content */
.detailCard {
  background: #111;
  border: none;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: #fff 1px solid;
}

.sectionTitle {
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #fff;
}

.overview {
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Seasons */
.seasonCard {
  background: #222;
  border: none;
  transition: transform 0.2s;
  border: #fff 1px solid;
}

.seasonCard:hover {
  transform: translateY(-5px);
}

/* Cast Grid */
.castGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  color: white;
}

.castMember {
  text-align: center;
}

.castImage {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

/* Sidebar */
.sidebarCard {
  background: #111;
  border: none;
  border-radius: 8px;
  border: #fff 1px solid;
}

.sidebarTitle {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.networkGrid,
.providerGrid {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  gap: 1rem;
  justify-content: flex-start;
}

.networkItem img,
.providerItem img {
  width: 60px;
  background: #fff;
  border-radius: 6px;
}

/* SeriesDetailsPage.module.css */
/* Add these new styles */

/* Genre List */
.genreList {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

/* Tabs Styling */
.nav-tabs {
  border-bottom: 2px solid #333;
  color: #fff !important;
  border: #fff 2px solid;
}

.nav-link {
  --bs-nav-link-color: white;
  color: #fff !important;
  border: none !important;
  background: transparent !important;
  position: relative;
  border: #fff 2px solid;
}

.nav-link.active {
  background: transparent !important;
  border-bottom: 3px solid #fff !important;
  font-weight: bold;
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

/* Season Poster */
.seasonPoster {
  border-radius: 8px;
  margin-bottom: 1rem;
}

/* Text Colors */
.title,
.sectionTitle,
.sidebarTitle,
.overview,
.seriesContainer,
.seriesContainer .card,
.seriesContainer .card-body {
  color: #fff !important;
}

/* Badge Styling */
.badge {
  color: #000 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* .headerContent {
      flex-direction: column;
      align-items: center;
      text-align: center;
  } */

  .posterColumn {
      width: 300px;
      margin-bottom: 1rem;
  }

  .poster {
    max-width: 200px;
  }

  .title {
      font-size: 2rem;
  }

  .actionButtons {
      justify-content: center;
  }

  .castGrid {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media (max-width: 576px) {
  .posterColumn {
    width: 250px;
  }

  .poster {
    max-width: 200px;
  }

  .backdropSection {
    height: 45vh;
  }

  .title {
      font-size: 1.75rem;
  }

  .sectionTitle {
      font-size: 1.5rem;
  }

  .castGrid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

/* Container and overall theme */
.movieContainer {
  background-color: #181818;
  color: #fff;
  padding: 1%;
  margin: 0;
  overflow: hidden;
  /* Prevent horizontal overflow if needed */
}

/* width */
.movieContainer::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  border-radius: 10px;
}

/* Track */
.movieContainer::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
.movieContainer::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border-radius: 10px;
}

/* Handle on hover */
.movieContainer::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  border-radius: 10px;
}

/* Backdrop Section with modern parallax effect */
.backdropSection {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.backdropImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
  transform: scale(1.1);
  transition: transform 0.5s ease, filter 0.5s ease;
}

.backdropSection:hover .backdropImage {
  transform: scale(1);
  filter: brightness(0.5);
}

.headerContent {
  position: relative;
  z-index: 2;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.posterColumn {
  padding: 1rem;
  text-align: center;
}

/* Updated poster styling to prevent cutting on mobile */
.poster {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.8);
  transition: transform 0.3s ease;
}

.poster:hover {
  transform: scale(1.05);
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.metaInfo {
  margin-top: 1rem;
}

.userActions {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.userActions button {
  font-size: 0.9rem;
  flex: 1 1 auto;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
  background-color: #1a1a1a;
}

/* Detail Card */
.detailCard {
  background-color: #1a1a1a;
  /* border: 1px solid #ffffff; */
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #fff;
}

/* Section Titles */
.sectionTitle {
  font-size: 1.5rem;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  /* border-bottom: 2px solid #e50914; */
}

.overview {
  font-size: 1rem;
  line-height: 1.5;
}

.subTitle {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

/* Genre list and sidebar */
.genreList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sidebarCard {
  background-color: #1a1a1a;
  /* border: 1px solid #ffffff; */
  border-radius: 8px;
  color: #fff;
  margin-bottom: 1rem;
}

.sidebarTitle {
  font-size: 1.3rem;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.productionInfo h5 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.financials p {
  margin: 0.3rem 0;
}

/* Cast and Crew Grid */
.castGrid {
  display: flex;
  overflow-x: auto;
  /* flex-wrap: wrap; */
  gap: 1rem;
  justify-content: space-around;
}

.castMember {
  width: 100px;
  flex: 0 0 100px;
  text-align: center;
}

.castImage {
  width: 100%;
  border-radius: 8px;
}

.castInfo {
  font-size: 0.75rem;
}

/* Media Carousel */
.mediaCarousel {
  max-height: 400px;
}

.mediaContainer {
  position: relative;
  width: 100%;
  height: 400px;
}

.mediaImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mediaMeta {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

/* Media Tabs & Video Grid */
.mediaTabs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.mediaSection {
  flex: 1;
  min-width: 300px;
}

.videoGrid {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding-bottom: 1rem;
}

.videoItem {
  flex: 0 0 300px;
}

.videoItem iframe {
  width: 100%;
  height: 200px;
  border: none;
  border-radius: 8px;
}

/* Provider Icons */
.providerLogo {
  height: 30px;
  margin: 0.5rem;
  transition: all 0.3s ease;
}

.providerLogo:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .backdropSection {
    height: 40vh;
  }

  .headerContent {
    padding: 0;
    margin: 0 auto;
  }

  .poster {
    max-width: 200px;
  }

  .title {
    font-size: 1.5rem;
  }

  .badge {
    font-size: 0.6rem !important;
  }

  .userActions button {
    width: 100%;
  }

  .videoItem {
    flex: 0 0 250px;
  }

  .videoItem iframe {
    height: 150px;
  }
}

@media (max-width: 576px) {
  .backdropSection {
    height: 40vh;
  }

  .headerContent {
    padding: 0;
    margin: 0 auto;
    font-size: small;
  }

  .poster {
    max-width: 200px;
  }

  .title {
    font-size: 1rem;
  }

  .badge {
    font-size: 0.6rem !important;
  }

  .sectionTitle {
    font-size: 1.25rem;
  }

  .subTitle {
    font-size: 1rem;
  }
}