/* Base Variables */
:root {
  --primary-color: #e50914;
  --background-dark: #181818;
  --text-primary: #fff;
  --transition-speed: 0.3s;
}

/* Horizontal Scroll Container */
.horizontal-scroll-wrapper {
  position: relative;
  margin-bottom: 0;
}
.scroll-container {
  overflow-x: auto;
  overflow-y: visible;
  padding: 1rem 0;
  scrollbar-width: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Items Layout */
.items-wrapper {
  display: flex;
  gap: 0.5rem;
  padding: 0 2rem;
}
.item-container {
  position: relative;
  transition: transform var(--transition-speed) ease;
  cursor: pointer;
}
.item-container.active {
  transform: scale(1);
  z-index: 100;
}

/* Thumbnails */
.thumbnail {
  width: 180px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  transition: all var(--transition-speed) ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.thumbnail.horizontal {
  width: 380px;
  height: 200px;
}

/* Scroll Icons */
.scroll-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--text-primary);
  width: 40px;
  height: 300px;
  font-size: 2rem;
  border-radius: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 2000;
  opacity: 0;
  pointer-events: none;
}
.horizontal-scroll-wrapper:hover .scroll-icon {
  opacity: 1;
  pointer-events: auto;
}
.scroll-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.scroll-left {
  left: 0;
}
.scroll-right {
  right: 0;
}

/* Modals */
/* Desktop */
.desktop-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  animation: fadeIn var(--transition-speed) ease;
}
.desktop-modal {
  position: relative;
  background: var(--background-dark);
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3);
  animation: scaleUp var(--transition-speed) ease forwards;
}
/* Mobile */
.mobile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(5px);
  animation: fadeIn var(--transition-speed) ease;
}
.mobile-modal {
  position: relative;
  background: var(--background-dark);
  border-radius: 8px;
  width: 95vw;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.3);
  animation: scaleUp var(--transition-speed) ease forwards;
}

/* Modal Content */
.preview-modal-content {
  position: relative;
  padding: 0;
  color: var(--text-primary);
}
.trailer-container {
  height: 200px;
  background: #000;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  width: 100%;
}
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 2rem;
  cursor: pointer;
  z-index: 100;
}
.content-wrapper {
  margin: 1.2rem;
}
.meta-info {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  font-size: 0.9rem;
  opacity: 0.8;
}
.action-buttons {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}
.icon-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}
.icon-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}
.description {
  font-size: small;
  text-align: justify;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes scaleUp {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}
@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Loading Spinner */
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 2rem auto;
}
.card-img-overlay .card-title {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 380px;
  z-index: 200;
}

/* Modal Transitions */
.modal-enter {
  opacity: 0;
  transform: scale(0.95);
}
.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.modal-exit {
  opacity: 1;
  transform: scale(1);
}
.modal-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}

/* Skeleton Loading */
.skeleton-card {
  background: #333;
  border-radius: 4px;
  width: 180px;
  height: 250px;
  animation: pulse 1.5s infinite;
}
.skeleton-card.horizontal {
  width: 380px;
  height: 200px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .thumbnail, .skeleton-card {
    width: 140px;
    height: 200px;
  }
  .thumbnail.horizontal, .skeleton-card.horizontal {
    width: 240px;
    height: 133px;
  }
  .modal-content {
    padding: 0;
  }
  .meta-info {
    flex-wrap: wrap;
  }
}