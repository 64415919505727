/* cover.css */
:root {
  --navbar-bg-color: transparent;
  --transition-easing: cubic-bezier(0.4, 0, 0.2, 1);
  --transition-duration: 0.3s;
}

/* Cover Container */
/* Default (navbar expanded): two lines => cover height is smaller */
.cover-container {
  position: relative;
  width: 100%;
  margin-top: 60px;
  height: calc(100vh - 120px);
  z-index: 900;
  overflow: hidden;
  transition: margin-top var(--transition-duration) var(--transition-easing),
              height var(--transition-duration) var(--transition-easing);
}

/* When navbar is collapsed (category selected), cover expands */
.cover-container.collapsed {
  height: calc(100vh - 60px);
}

/* Main Cover Image */
.cover-main {
  position: relative;
  display: flex;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  transition: background-image var(--transition-duration) var(--transition-easing),
              background-color var(--transition-duration) var(--transition-easing);
}

/* Cover Contents */
.cover-contents {
  margin-left: 60px;
  padding-bottom: 30px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1.6;
  color: white;
  z-index: 2;
}

.movie-title {
  font-size: 80px;
  line-height: normal;
}

.movie-overview {
  font-size: 18px;
  font-weight: normal;
  margin-top: 10px;
}

/* Buttons */
.btn-play,
.btn-download,
.btn-more {
  border-radius: 4px;
  font-size: 18px;
  padding: 8px 18px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 8px;
}
.btn-play {
  background-color: #fff;
  border: 1px solid #fff;
  color: #333;
}
.btn-play:hover {
  opacity: 0.8;
}
.btn-more {
  background-color: #545455;
  border: 1px solid #545455;
  color: white;
  opacity: 0.8;
}
.btn-more:hover {
  opacity: 1;
}

/* Faded Bottom Effect */
.faded-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8%;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5), #000);
  pointer-events: none;
  z-index: 1;
}

/* Navigation Controls for Cover */
.cover-controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 3;
}
.cover-controls button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}
@media (max-width: 768px) {
  .cover-controls {
    display: none;
  }
}

/* Slide Animations */
@keyframes slideOutLeft {
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(-100%); opacity: 0; }
}
.slide-out-left { animation: slideOutLeft 0.5s forwards; }

@keyframes slideInRight {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
.slide-in-right { animation: slideInRight 0.5s forwards; }

@keyframes slideOutRight {
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
}
.slide-out-right { animation: slideOutRight 0.5s forwards; }

@keyframes slideInLeft {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
.slide-in-left { animation: slideInLeft 0.5s forwards; }

/* Cover Skeleton */
.cover-skeleton {
  position: relative;
  height: 700px;
  background-color: #333;
  overflow: hidden;
}
.cover-skeleton-image {
  width: 100%;
  height: 100%;
  background: #000;
  animation: shimmer 1.5s infinite;
}
.cover-skeleton-contents {
  position: absolute;
  bottom: 30px;
  left: 60px;
  color: white;
}
.cover-skeleton-title {
  width: 60%;
  height: 80px;
  background: #777;
  margin-bottom: 10px;
  border-radius: 4px;
}
.cover-skeleton-overview {
  width: 80%;
  height: 40px;
  background: #777;
  margin-bottom: 20px;
  border-radius: 4px;
}
.cover-skeleton-buttons .btn-skeleton {
  display: inline-block;
  width: 140px;
  height: 40px;
  background: #777;
  border-radius: 4px;
  margin-right: 10px;
}

@keyframes shimmer {
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
}
.skeleton {
  background: linear-gradient(90deg, #777 25%, #888 50%, #777 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .cover-container, .cover-skeleton {
    height: calc(100vh - 250px);
    margin-top: 100px;
  }
  .cover-container{
    padding: 0 10px;
  }
  .cover-contents {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
  }
  .movie-title {
    font-size: 32px;  /* Adjust title size for mobile */
  }
  .movie-overview {
    font-size: 16px;
  }
  .btn-play, .btn-more {
    font-size: 14px;
    width: 43%;
    margin: auto;
    /* padding: 8px 16px; */
  }
}

@media (max-width: 768px) {
  .cover-main {
    object-fit: cover;
    margin: 15px;
    border: 1px solid white;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.5);
    height: calc(100% - 100px);
  }
  .cover-container.collapsed {
    height: calc(100vh - 300px);
    margin-top: 100px;
  }
}
