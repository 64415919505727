.row-container {
  margin-left: 5px;
}

.tiles-row-container {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: scroll;
  /* padding: 20px; */
}

.tiles-row-container::-webkit-scrollbar {
  display: none;
}

.row-title {
  color: #fff;
  z-index: 100;
}

.image-tile {
  max-width: 100%;
  border-radius: 4px;
  object-fit: contain;
  max-height: 150px;
  margin-right: 10px;
  transition: transform 450ms;
}

.image-tile:hover {
  transform: scale(1.08)
}

.image-tile-top-row {
  max-height: 250px;
}

.image-tile-top-row:hover {
  transform: scale(1.09)
}

.infinite-scroll-user {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  /* Optional: hide scrollbar for a cleaner look */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.infinite-scroll-user::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.loading {
  display: inline-block;
  padding: 20px;
  font-size: 18px;
  color: #fff;
}

.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;
  box-sizing: border-box;
}

.loading {
  display: inline-block;
  padding: 20px;
  font-size: 18px;
  color: #fff;
}