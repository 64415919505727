.download-card {
    /* margin: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .download-card:hover {
    transform: translateY(-5px);
  }
  
  .progress {
    height: 25px;
    margin: 15px 0;
  }
  
  .status-badge {
    font-size: 0.9rem;
    padding: 8px 12px;
    border-radius: 20px;
  }
  
  .file-info {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
  }